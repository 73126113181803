import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { getToken } from '../auth/state/auth.selectors';

// Representación del estado de la aplicación. Ajusta según sea necesario.
interface AppState {
  auth: { token: string };
}

/**
 * Interceptor que maneja la inclusión del token de autenticación en las solicitudes HTTP
 * que lo requieran según la configuración especificada.
 *
 * Utiliza el Store de NgRx para obtener el token actual.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /**
   * Rutas que requieren token para su acceso.
   */
  private routesRequiringToken = [
    // Agregar rutas que requieran token.
    'advisor/approved-companies',
    'advisor/associate-admin-company',
    'advisor/banner',
    'advisor/banner-package',
    'advisor/sort-banners',
    'advisor/info',
    'advisor/campaings',
    'advisor/campaing',
    'advisor/test-users',
    'advisor/test-user',
    'advisor/send-campaing',
    'appointments',
    'appointments/active-subservices',
    'appointments/branch',
    'appointments/cancel',
    'appointments/details',
    'clinic-register',
    'clinic-register/cie10',
    'clinic-register/doctor-registers',
    'clinic-register/employee',
    'company',
    'company/branch',
    'company/details',
    'company/branch/details',
    'doctor',
    'doctor/clinic-register-data',
    'doctor/manage-patients',
    'doctor/profile',
    'doctor/speciality',
    'employee-registers',
    'employee',
    'employee/branches',
    'employee/manage-patients',
    'images/avatar',
    'family-group',
    'advisor/family-groups/',
    'family-group/beneficiary',
    'family-group/request',
    'family-group/details',
    'family-group/pet',
    'family-group/request',
    'schedule/doctor',
    'search/schedule',
    'search/subservice-detail',
    'service',
    'service/company',
    'service/sub-service',
    'schedule/shift',
    'user',
    'user/company/aproved-status',
    'user/complete-google-profile',
    'user/experience',
    'user/options',
    'user/study',
    'user/merge-account',
    'menus',
    'schedule/min-max'
  ];

  /**
   * Inyecta el Store de la aplicación para acceder al estado.
   *
   * @param {Store<AppState>} store - Store de NgRx.
   */
  constructor(private store: Store<AppState>) { }

  /**
   * Interceptar y modificar la solicitud HTTP para incluir el token si es necesario.
   *
   * @param {HttpRequest<any>} request - Solicitud HTTP original.
   * @param {HttpHandler} next - Controlador HTTP que pasa la solicitud al siguiente middleware.
   * @returns {Observable<HttpEvent<any>>} - Observable que emite eventos HTTP para la solicitud.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // Obtiene el token del store y decide si la solicitud actual necesita el token.
    return this.store.select(getToken).pipe(
      first(),
      switchMap(token => {

        // Si hay un token y la solicitud lo requiere, modifica la solicitud.
        if (token && this.requiresToken(request.url)) {

          request = request.clone({
            setHeaders: {
              authorization: token
            }
          });
        }
        return next.handle(request);
      })
    );
  }

  /**
   * Determina si una URL dada requiere un token basado en las rutas especificadas.
   *
   * @private
   * @param {string} url - La URL para verificar.
   * @returns {boolean} - Verdadero si la URL requiere token, falso en caso contrario.
   */
  private requiresToken(url: string): boolean {
    return this.routesRequiringToken.some(route => url.includes(route));
  }
}
