import { userData } from 'src/app/auth/state/auth.selectors';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { getMenu, getRoles, getRolSelect, rolSession } from 'src/app/pages/session/state/session.selectors';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy  {

  @Input() createdAt!: string; // Fecha inicial pasada como input (en formato ISO)
  endDate!: Date; // Fecha final calculada
  totalDays!: number; // Días totales entre las fechas
  progress: number = 100; // Progreso inicial (100%)
  subscription!: Subscription;

  userData : any;


  activities = [
    {
      date: '07:00',
      day: 'PM',
      activity: 'Contactologia',
      name: 'Diego Ojeda',
      participants: [
        'https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/c61daa1c-5881-43f8-a50f-62be3d235daf',
      ]
    },
    {
      date: 15,
      day: 'wed',
      activity: 'Optometria',
      name: 'Jefry Osorio',
      participants: [
        'https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/c61daa1c-5881-43f8-a50f-62be3d235daf',
      ]
    },
    {
      date: 17,
      day: 'fri',
      activity: 'Tennis',
      name: 'Peque Vargas',
      participants: [
        'https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/32037044-f076-433a-8a6e-9b80842f29c9',
      ]
    },
    {
      date: 18,
      day: 'sat',
      activity: 'Hiking',
      name: 'Andres Paz',
      participants: [
        'https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/07d4fa6f-6559-4874-b912-3968fdfe4e5e',
      ]
    }
  ];

  doctors = [
    {
      name: 'Dr. Aliandro M',
      specialty: 'Nursing',
      rating: 4.3,
      address: 'Folsom Ave, Suite 600 San 795, Francisco, CADGE 94107',
      image: 'https://iamosahan.com/wrapbootstrap-pillarix/meetdactor/img/home/top-doctor-1.jpg',
      socialLinks: {
        twitter: 'https://twitter.com',
        facebook: 'https://facebook.com',
        instagram: 'https://instagram.com'
      }
    },
    {
      name: 'Dr. Sara J',
      specialty: 'Cardiology',
      rating: 4.8,
      address: 'Main St, Suite 300, New York, NY 10001',
      image: 'https://iamosahan.com/wrapbootstrap-pillarix/meetdactor/img/home/top-doctor-1.jpg',
      socialLinks: {
        twitter: 'https://twitter.com',
        facebook: 'https://facebook.com',
        instagram: 'https://instagram.com'
      }
    },
    {
      name: 'Dr. Robert P',
      specialty: 'Pediatrics',
      rating: 4.6,
      address: 'Broadway, Suite 500, Los Angeles, CA 90001',
      image: 'https://iamosahan.com/wrapbootstrap-pillarix/meetdactor/img/home/top-doctor-1.jpg',
      socialLinks: {
        twitter: 'https://twitter.com',
        facebook: 'https://facebook.com',
        instagram: 'https://instagram.com'
      }
    }
  ];
  constructor(
    private store: Store,
  ) { }

  ngOnInit() {
    this.store.select(getRolSelect).subscribe((rol) => (this.userData = rol))
    console.log(this.userData);
    this.initializeCountdown();
  }

  ngOnDestroy(): void {
    // Limpia la suscripción al destruir el componente
    this.subscription?.unsubscribe();
  }

  initializeCountdown() {
    // Calcula la fecha final

    const startDate = new Date(this.userData.data.companyStates.approvedState.createdAt).getTime();
    // const startDate = new Date(fecha).getTime();

    this.endDate = new Date(this.userData.data.companyStates.approvedState.expirationDate);

    // Calcula la cantidad total de días entre las fechas
    this.totalDays = Math.ceil((this.endDate.getTime() - startDate) / (1000 * 60 * 60 * 24));

    // Configura el intervalo para actualizar la barra de progreso
    this.subscription = interval(1000).subscribe(() => {
      const now = new Date().getTime();
      const distance = this.endDate.getTime() - now;

      if (distance <= 0) {
        alert('¡El tiempo ha terminado!');
        this.progress = 0;
        this.subscription.unsubscribe();
      } else {
        const daysRemaining = Math.ceil(distance / (1000 * 60 * 60 * 24));
        this.progress = (daysRemaining / this.totalDays) * 100;
      }
    });
  }
}
