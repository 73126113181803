import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService, ModalService, SweetAlertService } from '@services';
import { format, parse } from 'date-fns';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'info-shift-schedule',
  templateUrl: './info-shift-schedule.component.html',
  styleUrls: ['./info-shift-schedule.component.scss']
})
export class InfoShiftScheduleComponent {
  @Input() data: any;

  @Output() onCloseInfoSchedule = new EventEmitter<boolean>();
  @HostBinding('style.height') height: string = 'auto';

  editing: boolean = false
  hoursModified: boolean = false
  servicesModified: boolean = false
  loader: boolean = false
  dayOnShift!: string
  doctorSelected!: any
  shiftSelected!: any
  hoursSchedule!: any
  hoursSelected!: any

  editTimeForm!: FormGroup
  startAvailableHours: any[] = []
  endAvailableHours: any[] = []
  listCurrentServices: any[] = []
  oldListCurrentServices: any[] = []
  rangeHours: any[] = []

  shiftPackage!: any

  oldHoursSchedule!: any

  listServices: any[] = []
  listServicesRecord: Record<string, any> = {}
  oldServices: any[] = []
  newServices: any[] = []
  blockedService: boolean = false

  showErrorOnservices: boolean = false;

  options: AnimationOptions = { path: 'assets/lottie-animations/remove.json' };


  statusShiftList: Record<string, any> = {
    'active': { type: 'active', translate: 'activa' },
    'inactive': { type: 'inactive', translate: 'inactiva' },
    'paused': { type: 'paused', translate: 'pausada' },
    'blocked': { type: 'blocked', translate: 'bloqueda' }
  }

  constructor(
    private sweetAlert: SweetAlertService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    if (!this.data) return
    let { company, day, hour, shift, doctor, scheduleList, rangeHours, services, subservices } = this.data

    this.filterServices(services, hour.subServices);

    this.dayOnShift = day.slice(0, -3)
    this.shiftPackage = scheduleList[this.dayOnShift]

    this.hoursSelected = hour
    this.hoursSchedule = { startTime: `${hour.start}:00`, endTime: `${hour.end}:00` }
    this.doctorSelected = doctor
    this.rangeHours = rangeHours
    this.shiftSelected = shift

    this.oldServices = [...hour.subServices].sort()
    this.newServices = [...hour.subServices].sort()

    this.listServices = services.map((_service: any) => _service.subServices).flat()
    this.listServices.forEach((_service: any) => this.listServicesRecord[_service._id] = _service)

    this.editTimeForm = this.formBuilder.group({ startHourSelector: [null], endHourSelector: [null] })
    this.editTimeForm.controls['endHourSelector'].disable()

    this.rangeHours.forEach(rh => {
      let lapseTime = `${rh.split(':')[0]}:59 ${rh.split(' ')[1]}`
      this.startAvailableHours.push({ hour: rh, id: parse(rh, 'hh:mm aa', new Date()).getHours(), disable: false, setted: false })
      this.endAvailableHours.push({ hour: lapseTime, id: parse(rh, 'hh:mm aa', new Date()).getHours() + 1, disable: false, setted: false })
    })

    this.getAvailableHours(this.shiftPackage.shifts)
    this.listenChanges()
  }

  filterServices(serviceList: any[], subserviceSelectedList: string[]) {
    this.listCurrentServices = [];

    serviceList.forEach(service => {
      service.subServices.forEach((_service: any) => {
        subserviceSelectedList.includes(_service._id) && this.listCurrentServices.push(_service)
      });
    });

    this.oldListCurrentServices = this.listCurrentServices
  }

  listenChanges() {
    let startHourControl = this.editTimeForm.controls['startHourSelector'];
    let endHourControl = this.editTimeForm.controls['endHourSelector'];

    startHourControl.valueChanges.subscribe(startHour => {
      this.hoursSchedule.startTime = `${startHour}:00`
      this.hoursSchedule.endTime = null
      this.editTimeForm.controls['endHourSelector'].enable()
      this.setAvailableHours(startHour, undefined)
    })

    endHourControl.valueChanges.subscribe(endHour => {
      if (!endHour) return
      this.hoursSchedule.endTime = `${endHour}:00`
      this.hoursModified = true
    })
  }

  private getAvailableHours(shifts: any[]): void {
    let temp: any[] = []
    this.rangeHours.forEach(rh => temp.push({ hour: rh, id: rh.split(':')[0], disable: false }))
    shifts.length && shifts.forEach(shift => [...shift.hours].forEach(({ start, end }) => {
      this.hoursSelected.start !== start && this.hoursSelected.end !== end && this.setAvailableHours(start, end, true)
    }))
  }

  private setAvailableHours(startTime: string | undefined, endTime: string | undefined, setted: boolean = false, disabled: boolean = true) {
    let tmpStart: string
    let tmpEnd: string
    let indexStart: number
    let indexEnd: number

    let startTimeTemp: any[] = [...this.startAvailableHours]
    let endTimeTemp: any[] = [...this.endAvailableHours]

    if (setted && startTime && endTime) {
      tmpStart = this.getNormalHour(startTime)
      tmpEnd = this.getNormalHour(endTime)

      indexStart = startTimeTemp.findIndex((r: any) => r.hour == tmpStart)
      indexEnd = startTimeTemp.findIndex((r: any) => r.hour == tmpEnd)

      for (let i = indexStart; i < indexEnd; i++) {
        startTimeTemp[i].disable = disabled
        startTimeTemp[i].setted = setted
      }

      for (let i = indexStart; i < indexEnd; i++) {
        endTimeTemp[i].disable = disabled
        endTimeTemp[i].setted = setted
      }

      this.startAvailableHours = [...startTimeTemp]
      this.endAvailableHours = [...endTimeTemp]
    }

    if (!setted && startTime) {
      // this.selectedStartHour = startTime      
      endTimeTemp.forEach(recordTime => {
        if (!recordTime.setted) recordTime.disable = false
      })

      indexStart = endTimeTemp.findIndex((endTimeTempItem: any) => endTimeTempItem.id == Number(startTime))

      for (let i = 0; i <= indexStart; i++) endTimeTemp[i].disable = true

      this.endAvailableHours = [...endTimeTemp]
      this.editTimeForm.controls['endHourSelector'].setValue(null)
      this.editTimeForm.controls['endHourSelector'].enable()
    }
  }

  private getNormalHour(hour: string | number) {
    return format(parse(`${hour}:00`, 'HH:mm', new Date()), 'hh:mm a')
  }

  onRemoveSchedule() {
    let { _id: shifId } = this.shiftSelected
    this.sweetAlert.launchConfirmSwal({
      text: '¿Estas seguro de eliminar el horario seleccionado?',
      cancelButtonColor: 'var(--primary)',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#d33',
      title: 'Eliminar horario',
      icon: 'question',
    }).then((res) => {
      if (res) {
        this.loader = true
        let quantityHours = this.shiftSelected.hours.length
        quantityHours > 1 ? this.removeHourShift(shifId) : this.removeAllShift(shifId)
      }
    })
  }

  enableUpdate() {
    if (this.editing) {
      this.editing = false
      // setTimeout(() => this.height = '40vh', 100)
    } else {
      this.oldHoursSchedule = { ...this.hoursSchedule }
      // this.height = '50vh'
      setTimeout(() => this.editing = true, 100)
    }
  }

  isChecketService(_idService: string) {
    return this.newServices.some((_id: string) => _id === _idService);
  }

  onChangeServices(event: Event, idService: string) {
    let inputElement = event.target as HTMLInputElement;
    let setted = false;

    if (!inputElement.checked && this.listCurrentServices.length == 1) {
      this.showErrorOnservices = true
      inputElement.checked = true

      setTimeout(() => {
        this.showErrorOnservices = false
      }, 2_000);
      return
    }

    if (!inputElement.checked) {
      this.listCurrentServices = this.listCurrentServices.filter((_service: any) => _service._id !== idService)
    }

    if (inputElement.checked) {
      this.listCurrentServices.push(this.listServicesRecord[idService])
    }

    this.newServices = this.listCurrentServices.map((_service: any) => _service._id).sort()

    this.servicesModified = !this.isSameData(this.oldServices, this.newServices)
  }

  isSameData(firstList: string[], secondList: string[]) {
    if (firstList.length !== secondList.length) {
      return false;
    }

    let sortedArr1 = [...firstList].sort();
    let sortedArr2 = [...secondList].sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }

  updateSchedule() {
    let { numberOfAppointments, branchId, office, hours, _id } = this.shiftSelected

    let newHours = hours.map((hr: any) => {
      let { start: startOriginal, end: endOriginal } = this.hoursSelected
      let { startTime, endTime } = this.hoursSchedule
      let { start, end } = hr

      hr.subServices = this.newServices

      if (start === startOriginal && end === endOriginal) {
        hr.start = Number(startTime.split(':')[0])
        hr.end = Number(endTime.split(':')[0])
        return hr
      }

      return hr
    })

    let shiftData = { _id, branchId, office, numberOfAppointments: 4, hours: newHours }

    let data = { day: this.dayOnShift, doctorId: this.doctorSelected['employee']['_id'], shift: shiftData }

    this.apiService.putRequest(`schedule/shift`, data).subscribe(({ ok, message }) => {
      if (ok) {
        setTimeout(() => {
          this.loader = false
          this.sweetAlert.lauchSwalAsync('Se actualizó el horario correctamente', message, 'info').then(() => this.close())
        }, 1000)
      }
    })
  }

  private removeAllShift(shifId: string) {
    this.apiService.deleteRequest(`schedule/shift/${shifId}`).subscribe(({ ok, message }) => {
      if (ok) {
        setTimeout(() => {
          this.loader = false
          this.sweetAlert.lauchSwalAsync('Se eliminó el horario correctamente', message, 'info').then(() => this.close())
        }, 1000)
      }
    })
  }

  private removeHourShift(shifId: string) {
    let { numberOfAppointments, branchId, office, hours, _id } = this.shiftSelected

    let newHours = hours.filter((hr: any) => {
      let { start: startOriginal, end: endOriginal } = this.hoursSelected
      let { start, end } = hr
      return start !== startOriginal && end !== endOriginal
    })

    let shiftData = { _id, branchId, office, numberOfAppointments, hours: newHours }

    let data = { day: this.dayOnShift, doctorId: this.doctorSelected['employee']['_id'], shift: shiftData }

    this.apiService.putRequest(`schedule/shift`, data).subscribe(({ ok, message }) => {
      if (ok) {
        setTimeout(() => {
          this.loader = false
          this.sweetAlert.lauchSwalAsync('Se eliminó el horario correctamente', message, 'info').then(() => this.close())
        }, 1000)
      }
    })
  }

  cancelUpdate() {
    this.editing = false
    this.hoursSchedule = { ...this.oldHoursSchedule }
    this.listCurrentServices = this.oldListCurrentServices
    this.newServices = this.oldListCurrentServices.map((_service: any) => _service._id).sort()
  }

  close() {
    this.modalService.closeModal()
    this.onCloseInfoSchedule.emit()
  }
}
