import { Component, ElementRef, HostBinding, Input, QueryList, Renderer2, ViewChildren, ViewEncapsulation } from '@angular/core';
import { eachMinuteOfInterval, format, parse } from 'date-fns';
import { ApiService, IntrojsService, StatesNgrxService, SweetAlertService } from '@services';

import { ModalService } from 'src/app/reusable-modal/modal.service';
import { ScheduleSetterComponent } from './schedule-setter/schedule-setter.component';
import { InfoShiftScheduleComponent } from './info-shift-schedule/info-shift-schedule.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { SessionState } from 'src/app/pages/session/state/session.reducers';
import { getRolSelect } from 'src/app/pages/session/state/session.selectors';
import { AnimationOptions } from 'ngx-lottie';
import { take } from 'rxjs';
import { CompanyService } from 'src/app/roles/services/company.service';

@Component({
  selector: 'schedule-programator',
  templateUrl: './schedule-programator.component.html',
  styleUrls: ['./schedule-programator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleProgramatorComponent {
  @ViewChildren('sundayRef, mondayRef, tuesdayRef, wednesdayRef, thursdayRef, fridayRef, saturdayRef') templateRefs!: QueryList<ElementRef>;

  @Input() minutesStep: number = 60
  @Input() maxHour: string = '16:00'
  @Input() minHour: string = '08:00'

  @HostBinding('style.--hourtSegmentHeight') hourtSegments: string = '100% 48px'
  @HostBinding('style.--taskCardHeight') taskCardHeight: string = '48px';

  options: AnimationOptions = { path: 'assets/lottie-animations/calendar_edited.json' };

  private templateRefMap: { [key: string]: ElementRef } = {};

  branchSelected!: string
  doctorSelected!: string
  companyData!: any

  loader = false

  doctorForm: FormGroup

  subServicesData: any[] = []
  servicesData: any[] = []
  hourRange: any[] = []
  weekDays = [
    {
      "name": "Domingo",
      "abbreviations": {
        "simply": "Dom",
        "letter": "D"
      }
    },
    {
      "name": "Lunes",
      "abbreviations": {
        "simply": "Lun",
        "letter": "L"
      }
    },
    {
      "name": "Martes",
      "abbreviations": {
        "simply": "Mar",
        "letter": "M"
      }
    },
    {
      "name": "Miércoles",
      "abbreviations": {
        "simply": "Mié",
        "letter": "M"
      }
    },
    {
      "name": "Jueves",
      "abbreviations": {
        "simply": "Jue",
        "letter": "J"
      }
    },
    {
      "name": "Viernes",
      "abbreviations": {
        "simply": "Vie",
        "letter": "V"
      }
    },
    {
      "name": "Sábado",
      "abbreviations": {
        "simply": "Sáb",
        "letter": "S"
      }
    }
  ]

  scheduleList: any

  doctorList: any[] = []

  multiSelectMode = false;
  selectedShiftsHtml: Set<HTMLElement> = new Set();

  selectedShiftData: any[] = [];
  completeShiftData: any[] = [];
  case1Shifts: any[] = [];
  case2Shifts: any[] = [];

  daysOutsideRange: string[] = [];
  isInputEnabled: boolean = false;
  auxMinHour!: string;
  auxMaxHour!: string;

  constructor(
    private sessionStore: Store<SessionState>,
    private sweetAlert: SweetAlertService,
    // private introService: IntrojsService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private render: Renderer2,
    private statesNgrxService: StatesNgrxService,
    private companyService: CompanyService,
  ) {
    this.doctorForm = this.formBuilder.group({ doctorControl: [null, [Validators.required]] })
    // this.branchSelected = '65304c334f5364c6b0872770'
  }

  ngOnInit() {
    // this.generatehours()
    let rollSession$ = this.sessionStore.pipe(select(getRolSelect))
    rollSession$.pipe(take(1)).subscribe((companyData) => this.companyData = companyData)
  }

  changeBranch(event: any) {
    let { branchId } = event;
    this.branchSelected = branchId;
    this.initData();
    this.closeMultiselect();
    this.isInputEnabled = false;
  }

  changeDoctor() {
    this.doctorSelected = this.doctorForm.controls['doctorControl'].value
    this.callAppoiments()
    this.closeMultiselect();
    this.isInputEnabled = false;
  }

  closeMultiselect() {
    this.selectedShiftsHtml.forEach((element: HTMLElement) => {
      element.classList.remove('selected-click');
    });
    this.selectedShiftsHtml.clear();
    this.multiSelectMode = false;
    // this.completeShiftData = [];
    this.selectedShiftData = [];
  }

  toggleMultiSelect() {
    this.isInputEnabled = false;
    this.multiSelectMode = !this.multiSelectMode;
    if (!this.multiSelectMode) {
      this.closeMultiselect();
    }
  }

  initData() {
    let servicesUrl = `service/company/${this.companyData._idData}`
    let doctorsBranchUrl = `company/branch-personnel/${this.branchSelected}/doctors`

    this.apiService.getRequest(servicesUrl).subscribe(({ ok, body }) => {
      if (ok) this.servicesData = body
    })

    this.apiService.getRequest(doctorsBranchUrl).subscribe(({ ok, body }) => {
      if (ok) {
        if (!body.length) {
          this.doctorList = [{ name: 'No hay doctores para mostrar', employee: { _id: -1 } }]
          this.doctorForm.controls['doctorControl'].setValue(-1)
          this.doctorForm.controls['doctorControl'].disable()
          this.scheduleList = {
            "_id": "-1",
            "doctorId": "-1",
            "monday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "tuesday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "wednesday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "thursday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "friday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "saturday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "sunday": {
              "dayStatus": "blocked",
              "shifts": []
            },
            "scheduleStatus": "active"
          }

          this.templateRefs.forEach((ref: ElementRef) => (ref.nativeElement as HTMLElement).innerHTML = "");

          return
        }

        this.doctorSelected = body[0].employee._id
        this.doctorForm.controls['doctorControl'].setValue(this.doctorSelected)
        this.doctorForm.controls['doctorControl'].enable()

        this.doctorList = body.map((data: any) => {
          let name = `${data.employee.lastNames} ${data.employee.names}`
          return data = { ...data, name }
        })

        this.callAppoiments()
      }
    })
  }

  callAppoiments() {
    this.loader = true
    let schedulesUrl = `schedule/doctor/${this.doctorSelected}`
    this.apiService.getRequest(schedulesUrl).subscribe(({ ok, body }) => {
      if (!ok) return
      this.scheduleList = body[0];

      let { maxHours, minHours } = this.scheduleList;

      this.maxHour = maxHours;
      this.minHour = minHours;

      this.generatehours()

      setTimeout(() => {
        this.templateRefs.forEach((ref: ElementRef) => {
          (ref.nativeElement as HTMLElement).innerHTML = ""
          this.templateRefMap[(ref.nativeElement as HTMLElement).id] = ref
        });
        this.validateDays()
      }, 1000);
    })
  }

  generatehours() {
    let minDate = parse(this.minHour, 'HH:mm', new Date())
    let maxDate = parse(this.maxHour, 'HH:mm', new Date())

    let range = eachMinuteOfInterval({ start: minDate, end: maxDate }, { step: this.minutesStep })
    let times = range.map((date) => format(date, 'hh:mm a'))

    this.hourRange = times
    this.hourtSegments = `100% calc(100% / ${this.hourRange.length - 1})`
    this.taskCardHeight = `calc(100% / ${this.hourRange.length - 1} - 1px)`
  }

  validateDays() {
    this.generateShifts(this.scheduleList.sunday, 'sundayRef')
    this.generateShifts(this.scheduleList.monday, 'mondayRef')
    this.generateShifts(this.scheduleList.tuesday, 'tuesdayRef')
    this.generateShifts(this.scheduleList.wednesday, 'wednesdayRef')
    this.generateShifts(this.scheduleList.thursday, 'thursdayRef')
    this.generateShifts(this.scheduleList.friday, 'fridayRef')
    this.generateShifts(this.scheduleList.saturday, 'saturdayRef')
    setTimeout(() => this.loader = false, 300)
  }

  cleanMatchingShifts() {

    this.case1Shifts = [];
    this.case2Shifts = [];

    // Filtrar `completeShiftData` para conservar solo los turnos cuyo `branchId` coincida con `this.branchSelected`
    this.completeShiftData = this.completeShiftData.filter((selectedShift) => {
      return selectedShift.branchId === this.branchSelected;
    });

    // Aplicar la lógica de coincidencia de horarios
    this.completeShiftData.forEach((selectedShift) => {
      // Buscar el turno en `selectedShiftData` usando `_id`
      const shiftEntry = this.selectedShiftData.find((entry) => entry._id === selectedShift._id);

      if (shiftEntry) {
        // Verificar si todos los horarios coinciden
        const allHoursMatch = selectedShift.hours.every((selectedHour: any) =>
          shiftEntry.hours.some(
            (shiftDataHour: any) =>
              shiftDataHour.start === selectedHour.start &&
              shiftDataHour.end === selectedHour.end
          )
        );

        if (allHoursMatch) {
          // Caso 1: Todos los horarios coinciden
          this.addToCase1Shifts(selectedShift);
        } else {
          // Caso 2: Solo algunos horarios coinciden
          this.addToCase2Shifts(this.processCase2(selectedShift, shiftEntry));
        }
      }
    });
  }

  /**
   * Agrega un turno a `case1Shifts`, asegurándose de que no haya duplicados por `_id`.
   */
  addToCase1Shifts(selectedShift: any) {
    if (!this.case1Shifts.some((shift: any) => shift._id === selectedShift._id)) {
      this.case1Shifts.push(selectedShift);
    }
  }

  /**
   * Agrega un turno a `case2Shifts`, asegurándose de que no haya duplicados por `_id`.
   */
  addToCase2Shifts(selectedShift: any) {
    if (!this.case2Shifts.some((shift: any) => shift._id === selectedShift._id)) {
      this.case2Shifts.push(selectedShift);
    }
  }

  /**
   * Procesa un turno con horarios parcialmente coincidentes.
   * Filtra los horarios que no tienen coincidencias exactas y retorna el turno modificado.
   */
  processCase2(selectedShift: any, shiftEntry: any) {
    // Filtrar los horarios que no tienen coincidencias en `selectedShiftData`
    selectedShift.hours = selectedShift.hours.filter(
      (selectedHour: any) =>
        !shiftEntry.hours.some(
          (shiftDataHour: any) =>
            shiftDataHour.start === selectedHour.start &&
            shiftDataHour.end === selectedHour.end
        )
    );
    return selectedShift; // Retornar el turno modificado para agregar a `case2Shifts`
  }

  /**
   * Calcula el total de horarios seleccionados en `selectedShiftData`.
   * Retorna 0 si no hay datos.
   */
  getTotalHoursCount(): number {
    if (!this.selectedShiftData || !Array.isArray(this.selectedShiftData)) {
      return 0;
    }
    return this.selectedShiftData.reduce((total, item) => {
      return total + (item.hours ? item.hours.length : 0);
    }, 0);
  }

  /**
   * Limpia y clasifica los turnos en `case1Shifts` y `case2Shifts` según los horarios seleccionados.
   * Filtra los turnos que pertenecen a la sucursal seleccionada y aplica la lógica de coincidencia de horarios.
   */
  onRemoveSchedule() {
    this.cleanMatchingShifts();
    // Validar condiciones antes de eliminar
    if ((this.case1Shifts.length < 1 && this.case2Shifts.length < 1) || this.selectedShiftData.length < 1) {
      this.sweetAlert.lauchSwalAsync(
        'Advertencia',
        'Debe seleccionar al menos un elemento antes de eliminar.',
        'warning'
      ).then(() => this.close());
      return;
    }

    this.sweetAlert.launchConfirmSwal({
      text: 'Si eliminas turnos, las citas existentes deberán ser reagendadas manualmente',
      cancelButtonColor: 'var(--primary)',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#d33',
      title: '¡Atención!',
      icon: 'warning',
    }).then((res) => {
      if (res) {
        this.loader = true;

        const totalShifts = this.case1Shifts.length + this.case2Shifts.length; // Total de operaciones
        let completedOperations = 0; // Contador de operaciones completadas

        const onComplete = () => {
          completedOperations++;
          if (completedOperations === totalShifts) {
            // Mostrar SweetAlert al finalizar todas las operaciones
            this.loader = false;
            this.sweetAlert
              .lauchSwalAsync('Éxito', 'Se eliminaron todos los horarios seleccionados correctamente.', 'info')
              .then(() => this.close());
            this.initData(); // Refrescar los datos
            this.closeMultiselect();
          }
        };

        // Procesar caso 1
        this.case1Shifts.forEach((shiftEntry) => {
          this.removeAllShift(shiftEntry._id, onComplete);
        });

        // Procesar caso 2
        this.case2Shifts.forEach((shiftEntry) => {
          this.removeHourShift(shiftEntry, onComplete);
        });
      }
    });
  }

  /**
   * Elimina un horario específico (`hour`) de un turno.
   * Llama a la API y ejecuta el callback `onComplete` al finalizar.
   */
  private removeHourShift(shiftEntry: any, onComplete: () => void) {
    const data = { day: shiftEntry.day, doctorId: this.doctorSelected, shift: shiftEntry };

    this.apiService.putRequest(`schedule/shift`, data).subscribe({
      next: ({ ok, message }) => {
        if (ok) {}
      },
      error: (err) => {
        console.error('Error eliminando horario:', err);
      },
      complete: () => {
        onComplete(); // Llamar onComplete al finalizar la operación
      },
    });
  }

  /**
   * Elimina un turno completo.
   * Llama a la API y ejecuta el callback `onComplete` al finalizar.
   */
  private removeAllShift(shiftId: any, onComplete: () => void) {
    this.apiService.deleteRequest(`schedule/shift/${shiftId}`).subscribe({
      next: ({ ok, message }) => {
        if (ok) {
          // console.log('Turno eliminado correctamente:', shiftId);
        }
      },
      error: (err) => {
        // console.error('Error eliminando turno:', err);
      },
      complete: () => {
        onComplete(); // Llamar onComplete al finalizar la operación
      },
    });
  }

  close() {
    this.modalService.closeModal()
  }

  /**
   * Agrega un turno (`shift`) para un día específico.
   * Si el turno está bloqueado, muestra una alerta.
   * Si está permitido, abre un modal para configurar el turno seleccionado.
   */
  addShift(event: MouseEvent, shift: any, day: string) {
    if (!this.multiSelectMode) {
      let { dayStatus } = shift;
      let targetElement = event.target as HTMLElement;
      let hasAttribute = targetElement.getAttribute('data-task-element')

      if (dayStatus === 'blocked') {
        this.sweetAlert.lauchSwal('¡No permitido!', 'La sucursal debe tener al menos un doctor para poder establecer el horario', 'info')
        return
      }

      let scheduleSetterInstance = this.modalService.openModal(ScheduleSetterComponent, {
        modalClassContent: 'modal-content-adjust',
        modalClassBody: 'modal-body-adjust',
        modalClass: 'modal-dialog modal-lg',
        dataKeyboard: false,
        static: 'static',
        footer: false,
        header: false,
      }, {
        doctorId: this.doctorForm.controls['doctorControl'].value,
        companyId: this.companyData._idData,
        subservices: this.subServicesData,
        scheduleList: this.scheduleList,
        branchId: this.branchSelected,
        services: this.servicesData,
        rangeHours: this.hourRange,
        shifts: shift.shifts,
        day
      })

      scheduleSetterInstance.onCloseScheduleSetter.subscribe(() => {
        this.callAppoiments()
        setTimeout(() => this.statesNgrxService.updateGlobalUser(), 1000)
      });
    }
  }

  showShiftInformation(shift: any, hour: any, day: any, event: Event) {
    let doctor = this.doctorList.filter((doc) => doc.employee._id === this.doctorSelected)[0]

    let infoScheduleInstance = this.modalService.openModal(InfoShiftScheduleComponent, {
      modalClassContent: 'modal-content-adjust',
      modalClassBody: 'modal-body-adjust',
      modalClass: 'modal-dialog modal-lg',
      dataKeyboard: false,
      static: 'static',
      footer: false,
      header: false,
    }, {
      subservices: this.subServicesData,
      scheduleList: this.scheduleList,
      services: this.servicesData,
      rangeHours: this.hourRange,
      company: this.companyData,
      shiftList: [],
      shift: shift,
      hour: hour,
      day: day,
      doctor
    })

    event.stopPropagation()

    infoScheduleInstance.onCloseInfoSchedule.subscribe(() => this.callAppoiments())
  }

  showShiftInformationNotPermited(event: Event) {
    this.sweetAlert.lauchSwal('¡No permitido!', 'No es posible visualizar, seleccionar o editar, el horario pertenece a otra sucursal', 'info')
    // this.sweetAlert.showInfo('¡No permitido!', 'No es posible visualizar o editar, el horario pertenece a otra sucursal')
    event.stopPropagation()
  }

  generateShifts(dayShift: any, day: string) {
    let { dayStatus, shifts } = dayShift
    let shiftsDay: any[] = []
    dayStatus === 'active' && shifts.forEach((shift: any) => shiftsDay.push(this.generateHourByShifts(shift, day)));

    if (!shiftsDay.length) return

    let shiftsOut = shiftsDay.flat()

    this.templateRefMap[day]

    shiftsOut?.forEach(shift => this.render.appendChild(this.templateRefMap[day].nativeElement, shift))
  }

  /**
   * Genera elementos visuales (`HTML`) para los horarios de un turno.
   * Evita duplicados en `completeShiftData` y permite seleccionar horarios en modo multiselección.
   */
  generateHourByShifts(shift: any, day: string) {
    // Verificar si `completeShiftData` ya contiene un turno con el mismo `_id`
    const exists = this.completeShiftData.some(existingShift => existingShift._id === shift._id);

    if (!exists) {
      // Agregar el turno (`shift`) al array `completeShiftData` solo si no existe

      let dayclean = day.replace(/Ref$/, '');

      this.completeShiftData.push({ ...shift, day: dayclean });
    }

    let { shiftStatus, branchId, office, hours } = shift
    let sameBranch = this.branchSelected == branchId
    let shiftsHOurs: any[] = []

    hours.forEach((hour: any) => {
      let { height, position } = this.getHeightAndPosition(hour)

      let dataTask: IRenderElementOptions = { type: 'div', classes: ['task__list__card', shiftStatus], styles: { height, top: position }, attributes: { title: 'Ver informacion de horario' } }

      // dataTask.clickAction = (ev: Event) => sameBranch ? this.showShiftInformation(shift, hour, day, ev) : this.showShiftInformationNotPermited(ev)

      dataTask.clickAction = (ev: Event) => {
        const target = ev.currentTarget as HTMLElement;

        if (this.multiSelectMode && sameBranch) {
          // Generar una clave única utilizando shiftId
          const key = `${shift._id}`;

          // Buscar el turno en `selectedShiftData` usando la clave
          let shiftEntry = this.selectedShiftData.find((entry) => entry._id === key);

          if (!shiftEntry) {
            // Almacenar el objeto completo de `shift` (con todos sus datos) en `selectedShiftData`
            shiftEntry = {
              ...shift,
              day: day.replace(/Ref$/, ''), // Eliminar "Ref" al final de `day`
              hours: []
            };
            this.selectedShiftData.push(shiftEntry!);
          }

          // Verificar si ya existe un horario idéntico en hours
          const isDuplicate = shiftEntry!.hours.some((existingHour: { start: string; end: string }) =>
            existingHour.start === hour.start && existingHour.end === hour.end
          );

          if (!isDuplicate) {
            // Agregar el objeto completo `hour` al shift específico si no es duplicado
            shiftEntry!.hours.push({ ...hour });
            this.selectedShiftsHtml.add(target);
            target.classList.add('selected-click');
          } else {
            // Eliminar el horario específico si ya existe
            shiftEntry!.hours = shiftEntry!.hours.filter(
              (existingHour: { start: string; end: string }) =>
                !(existingHour.start === hour.start && existingHour.end === hour.end)
            );
            this.selectedShiftsHtml.delete(target);
            target.classList.remove('selected-click');
            if (shiftEntry!.hours.length === 0) {
              // Obtener el índice de `shiftEntry` en `selectedShiftData`
              const shiftEntryIndex = this.selectedShiftData.findIndex(
                (entry) => entry._id === key
              );

              if (shiftEntryIndex !== -1) {
                // Eliminar el elemento si no tiene horarios
                this.selectedShiftData.splice(shiftEntryIndex, 1);
              }
            }
          }

          // Si no quedan horarios, eliminar el turno completo
          if (shiftEntry!.hours.length === 0) {
            this.selectedShiftData = this.selectedShiftData.filter(
              (entry) => entry._id !== key
            );
          }
        } else {
          // Lógica original si el multiselector está desactivado
          sameBranch
            ? this.showShiftInformation(shift, hour, day, ev)
            : this.showShiftInformationNotPermited(ev);
        }
      };

      dataTask.attributes.title = sameBranch ? 'Ver informacion de horario' : `El horario pertenece a otra sucursal`

      !sameBranch && dataTask.classes?.push('not_branch', 'not_clickable')

      let taskContainer = this.createRenderElement(dataTask)

      // let labelText = `${office}<br>${this.getNormalHour(hour.start)} - ${this.getNormalHour(hour.end)}` // TODO finish

      let labelTask = this.createRenderElement({ type: 'span', classes: ['office', 'ellipsis__text'], content: office, attributes: { title: office } })
      taskContainer.appendChild(labelTask)

      let iconTask = this.createRenderElement({ type: 'i', classes: ['fa', 'fa-clock-o'] })
      // taskContainer.appendChild(iconTask)

      let normalHours = `${this.getNormalHour(hour.start)} - ${this.getNormalHour(hour.end)}`
      let labelHours = this.createRenderElement({ type: 'span', classes: ['hour', 'ellipsis__text'], content: `${normalHours}`, attributes: { title: normalHours } })
      taskContainer.appendChild(labelHours)

      // <span class="hour">09:00 - 11:00</span>
      shiftsHOurs.push(taskContainer)
    })
    return shiftsHOurs
  }

  getHeightAndPosition(hour: { start: string, end: string }) {
    let { start, end } = hour
    let startDateTmp = this.getNormalHour(start)
    let endDateTmp = this.getNormalHour(end)
    let idxStart = this.hourRange.findIndex(hrr => hrr == startDateTmp)
    let idxEnd = this.hourRange.findIndex(hrr => hrr == endDateTmp)

    return { height: `calc((100% / ${this.hourRange.length - 1} - 1px) * ${idxEnd - idxStart})`, position: `calc((100% / ${this.hourRange.length - 1}) * ${idxStart} + 2px)` }
  }

  getNormalHour(hour: string | number) {
    return format(parse(`${hour}:00`, 'HH:mm', new Date()), 'hh:mm a')
  }

  private createRenderElement(options: IRenderElementOptions): HTMLElement {
    const { type, classes, content, clickAction, attributes, directives, styles } = options
    const tmpElement = this.render.createElement(type)

    if (content) {
      const tmpTextElement = this.render.createText(content)
      this.render.appendChild(tmpElement, tmpTextElement)
    }

    clickAction && this.render.listen(tmpElement, 'click', (event: Event) => clickAction(event))

    classes?.length && classes.forEach(cls => this.render.addClass(tmpElement, cls))

    if (attributes) {
      for (const attrName in attributes) {
        if (attributes.hasOwnProperty(attrName)) {
          const attrValue = attributes[attrName];
          this.render.setAttribute(tmpElement, attrName, attrValue);
        }
      }
    }

    if (styles) {
      for (const styleName in styles) {
        if (styles.hasOwnProperty(styleName)) {
          const styleValue = styles[styleName];
          this.render.setStyle(tmpElement, styleName, styleValue);
        }
      }
    }
    return tmpElement
  }

  public dayTranslations: { [key: string]: string } = {
    monday: 'lunes',
    tuesday: 'martes',
    wednesday: 'miércoles',
    thursday: 'jueves',
    friday: 'viernes',
    saturday: 'sábado',
    sunday: 'domingo'
  };

  /**
   * Formats a given time string from "HH:mm" format to "hh:mm AM/PM" format.
   * @param time - The time string in "HH:mm" format (24-hour).
   * @returns The formatted time string in "hh:mm AM/PM" format.
   */
  formatToAmPm(time: string | undefined): string {
    if (!time) {
      return '';
    }

    const [hour, minute] = time.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${this.padZero(hour12)}:${this.padZero(minute)} ${period}`;
  }

  /**
   * Pads a number with a leading zero if it is less than 10.
   * @param value - The number to pad.
   * @returns The number as a string, padded with a leading zero if necessary.
   */
  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  /**
   * Handles changes to time input values, updating minHour or maxHour
   * and converting times as needed between morning and afternoon formats.
   * @param type - Specifies whether the change is for 'min' or 'max' hour.
   */
  onTimeChange(type: 'min' | 'max') {
    if (type === 'min') {
      this.minHour = this.setTimeToFullHour(this.minHour);
      const [minHours] = this.minHour.split(':').map(Number);
      if (minHours >= 12) {
        this.minHour = this.convertToMorningFormat(minHours);
      }
    } else if (type === 'max') {
      this.maxHour = this.setTimeToFullHour(this.maxHour);
      const [maxHours] = this.maxHour.split(':').map(Number);
      if (maxHours < 12) {
        this.maxHour = this.convertToAfternoonFormat(maxHours);
      }
    }
  }

  /**
   * Converts a 24-hour format time in the morning to its afternoon equivalent.
   * @param hours - The hour in 24-hour format.
   * @returns The converted hour in "HH:00" afternoon format.
   */
  private convertToAfternoonFormat(hours: number): string {
    const newHours = hours === 0 ? 12 : hours + 12;
    return `${newHours.toString().padStart(2, '0')}:00`;
  }

  /**
   * Converts a 24-hour format time in the afternoon to its morning equivalent.
   * @param hours - The hour in 24-hour format.
   * @returns The converted hour in "HH:00" morning format.
   */
  private convertToMorningFormat(hours: number): string {
    const newHours = hours === 12 ? 0 : hours > 12 ? hours - 12 : hours;
    return `${newHours.toString().padStart(2, '0')}:00`;
  }

  /**
* Formats a given time to "HH:00" format, ensuring two-digit hour representation.
* @param time - The time string in "HH:mm" format.
* @returns The formatted time string in "HH:00" format.
*/
  private setTimeToFullHour(time: string): string {
    const [hours] = time.split(':');
    const formattedHours = hours.padStart(2, '0');
    return `${formattedHours}:00`;
  }

  enableEdit() {
    this.isInputEnabled = true;
    this.auxMinHour = this.minHour;
    this.auxMaxHour = this.maxHour;
    this.closeMultiselect();
  }

  /**
   * Handles the save operation. If input is enabled, prepares the payload
   * and (in a real implementation) sends a PUT request to update the schedule.
   * If input is not enabled, it toggles the input to be enabled.
   */
  onSave() {
    this.getDaysOutsideRange(this.scheduleList);
    const daysList = this.daysOutsideRange.join(', ');

    // editas
    if (this.auxMinHour === this.minHour && this.auxMaxHour === this.maxHour) {
      this.isInputEnabled = false;
      this.loader = false;
    } else if (this.daysOutsideRange.length == 0) {
      const payload = {
        doctorId: this.doctorSelected,
        minHours: this.minHour,
        maxHours: this.maxHour,
      };

      this.isInputEnabled = false;
      this.companyService.updateAgendaRangeCompany(payload)
        .then(response => {
          this.generatehours();
          this.isInputEnabled = false;
          this.initData();
        })
        .catch(error => {
          // console.error('Error al actualizar:', error);
        })
        .finally(() => {
        });
    } else {
      this.isInputEnabled = false;
      this.loader = false;
      this.sweetAlert.lauchSwal('Restricción de horario', `Los siguientes días tienen horarios fuera del rango permitido: ${daysList}. Por favor, ajuste los horarios para continuar.`, 'warning')
    }
  }

  getDaysOutsideRange(schedule: any): void {
    // Convertir minHour y maxHour a enteros
    const minHourInt = parseInt(this.minHour.split(':')[0], 10);
    const maxHourInt = parseInt(this.maxHour.split(':')[0], 10);

    // Limpiar el array antes de llenarlo
    this.daysOutsideRange = [];

    // Recorrer las claves de los días en el objeto (lunes a domingo)
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    days.forEach(day => {
      const dayData = schedule[day];

      // Verificar si el día está activo y tiene turnos
      if (dayData.dayStatus === 'active' && dayData.shifts.length > 0) {
        let isOutsideRange = false; // Bandera para indicar si algún turno está fuera del rango

        dayData.shifts.forEach((shift: any) => {
          shift.hours.forEach((hour: any) => {
            // Comparar el horario de inicio y fin con minHourInt y maxHourInt
            if (hour.start < minHourInt || hour.end > maxHourInt) {
              isOutsideRange = true;
            }
          });
        });

        // Si encontramos un turno fuera del rango, agregamos el día traducido al array
        if (isOutsideRange) {
          this.daysOutsideRange.push(this.dayTranslations[day]);
        }
      }
    });
  }
}

interface IRenderElementOptions {
  // attrType?: string;
  // attrValue?: string;
  attributes?: any;
  classes?: string[];
  clickAction?: Function;
  content?: any;
  directives?: IDirectiveOpts[];
  styles?: any;
  type: string;
}

interface IDirectiveOpts {
  directiveClass: any,
  entries?: any[]
}
