import { Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { daily } from "./daily.animation";
import { ApiService } from '@services';
import { AnimationOptions } from 'ngx-lottie';
import { isBefore, isToday, parse } from 'date-fns';
import { es } from 'date-fns/locale';

// import { HourDetailComponent } from './../hour-detail/hour-detail.component';

@Component({
  selector: 'daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss'],
  // host: { '[@daily]': 'in' },
  // animations: [daily]
})
export class DailyComponent {
  @ViewChildren('hourDetailRef', { read: ViewContainerRef }) private detailHourRef!: QueryList<ViewContainerRef>;

  @Output() data: any = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<void>();

  @Input() show: boolean = false;
  @Input() init: any = {};

  private _hourDetailActive: boolean = true;

  loadingHours: boolean = false
  hoursData!: any[]

  options: AnimationOptions = { path: 'assets/lottie-animations/searching.json' };

  constructor(private apiService: ApiService) { }

  selectHour(_hour: any, event?: MouseEvent) {

    let alreadyActive = document.querySelector('.sub__hours.active')

    const target = <HTMLElement>event?.target
    const targetParent = <HTMLElement>target.parentElement
    const mainParent = <HTMLElement>targetParent.parentElement
    const subHours = <HTMLElement>mainParent.childNodes[1]

    subHours.classList.toggle('active')

    if (alreadyActive) {
      alreadyActive.classList.remove('active')
      let hourButton = <HTMLElement>alreadyActive.childNodes[0]
      let nextButton = <HTMLElement>alreadyActive.childNodes[1]

      alreadyActive.classList.remove('active')
      hourButton.classList.remove('active')
      nextButton.classList.remove('active')
    }

    // const label = <HTMLElement>target.closest('.hours__item')?.childNodes[0]
    // const nextButton = <HTMLElement>target.closest('.hours__item')?.childNodes[1]

    // targetParent.classList.add('active')
    // label.classList.add('active')
    // nextButton.classList.add('active')

    target.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  selectSchedule(event: MouseEvent) {
    let labelActive = document.querySelector(".hours__item__label.active")
    let nextActive = document.querySelector(".hours__item__next.active")

    let target = <HTMLElement>event?.target
    let parent = target.parentElement
    let hourLabel = <HTMLElement>parent?.childNodes[0]
    let nextButton = <HTMLElement>parent?.childNodes[1]

    if (labelActive || nextActive) {
      labelActive?.classList.remove('active')
      nextActive?.classList.remove('active')
    }

    hourLabel.classList.add('active')
    nextButton.classList.add('active')

    target.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  confirmHour(hour: any) {
    this.data.emit(hour)
  }

  closeDaily() {
    this.data.emit('some from daily');
  }

  closeComponent() {
    this.closeEvent.emit();
  }

  getHours(endPoint: string): void {
    if (!endPoint) return
    let inDate = parse(`${this.init.date}`, 'yyyy-MM-dd', new Date(), { locale: es })

    this.show = true
    this.hoursData = []
    this.loadingHours = true
    this.apiService.getRequest(endPoint).subscribe(async ({ ok, body }) => {

      setTimeout(() => {
        this.loadingHours = false
        if (!ok) return

        body.forEach((_hours: any) => this.hoursData.push(..._hours.hours))

        isToday(inDate) && this.hoursData.map(hr => {
          hr.available = hr.available ? !isBefore(parse(hr.hour, 'HH:mm', new Date()), new Date()) : hr.available
          return hr
        })
      }, 1000);
    })

    // return Array.from(Array(12).keys());
  }
}
