
    <section class="content">
      <div class="left-content">
        <div class="activities">
          <div class="container-title mb-3">
            <h3 class="animated fadeIn">Bienvenido&nbsp;</h3>
            <h3 class="title-desktop animated fadeIn">{{userData.name}}</h3>
          </div>
          <div class="activity-container">
            <div class="image-container img-one">
              <div class="overlay">
                <h3>Citas hoy</h3>
                <div class="text-center text-statics">
                  <i class="text-warning fa fa-bell-o text-statics"></i> 2
                </div>
              </div>
            </div>

            <div class="image-container img-two">
              <div class="overlay">
                <h3>Pacientes</h3>
                <div class="text-center text-statics">
                  <i class="text-info fa fa-users  text-statics"></i> 998
                </div>
              </div>
            </div>

            <div class="image-container img-three">
              <div class="overlay">
                <h3>Personal</h3>
                <div class="container-slider">
                  <div id="doctorCarousel" class="carousel slide w-100 h-100" data-ride="carousel">
                    <div class="carousel-inner w-100 h-100" role="listbox">
                      <div *ngFor="let doctor of doctors; let i = index"
                           class="carousel-item w-100 h-100"
                           [ngClass]="{ active: i === 0 }">
                        <!-- Doctor Card -->
                        <article class="card-doctor">
                          <div class="card-image">
                            <img [src]="doctor.image" [alt]="doctor.name" />
                          </div>
                          <h2 class="card-name">{{ doctor.name }}</h2>
                          <p class="card-specialty">{{ doctor.specialty }}</p>
                        </article>
                      </div>
                    </div>

                    <!-- Carousel Controls -->
                    <a class="carousel-control-prev button-prev" href="#doctorCarousel" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next button-next" href="#doctorCarousel" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div>

              </div>
            </div>

            <div class="image-container img-four">
              <div class="overlay">
                <h3>Servicios</h3>
                <div class="text-center text-statics">
                  <i class="fa fa-briefcase text-statics"></i> 998
                </div>
              </div>
            </div>

            <div class="image-container img-five">
              <div class="overlay">
                <h3>Visitas</h3>
                <div class="text-center text-statics">
                  <i class="text-info fa fa-eye text-statics"></i> 998
                </div>
              </div>
            </div>

            <div class="image-container img-six">
              <div class="overlay">
                <h3>Citas totales</h3>
                <div class="text-center text-statics">
                  <i class="text-success fa fa-line-chart text-statics"></i> 6905
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="left-bottom">
          <div class="weekly-schedule">
            <h1>Citas para hoy</h1>
            <div class="calendar">
              <div class="day-and-activity" *ngFor="let activity of activities; let i = index" [class]="'activity-' + (i + 1)">
                <div class="day">
                  <h1>{{ activity.date }}</h1>
                  <p>{{ activity.day }}</p>
                </div>
                <div class="activity">
                  <h2>{{ activity.activity }}</h2>
                  <div class="participants">
                    <img [src]="activity.participants" /> <p class="">{{ activity.name }}</p>

                  </div>
                </div>
                <button class="btn">Ver</button>
              </div>
            </div>
          </div>

          <div class="personal-bests">
            <h1>Estado de suscripción</h1>
            <div class="personal-bests-container">
              <div class="active-calories">
                <h1 style="align-self: flex-start"></h1>
                  <div class="progress-container">
                    <div class="progress-bar"  [style.width.%]="progress">{{ progress.toFixed(2) }}%</div>
                  </div>
                  <p>Quedan ({{ (totalDays * progress / 100).toFixed(0) }} días) para terminar.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
