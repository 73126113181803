<aside class="left-sidebar " [ngClass]="{'active': isSidebarOpen}">
  <div class="scroll-sidebar">
    <div class="custom-user-profile" [routerLink]="getProfileRoleUrl" routerLinkActive="active">
      <img [src]="rolSelect.avatar | getImage" alt="user" class="custom-avatar" />
      <h5>{{ rolSelect.name }}</h5>
      <small>{{ getProfileRoleName(rolSelect.rol) }}</small>
    </div>
    <nav class="sidebar-nav">
      <ul id="sidebarnav">
        <!-- User Profile Section -->
        <!-- <li *ngIf="rolSelect.rol == 'doctor' || rolSelect.rol == 'provider' " class="user-profile" >
          <a
            (click)="goToDashboard()"
            class="waves-effect waves-dark img_perfil"
            href="javascript:void(0)"
          >
          <i class="fa fa-area-chart" style="color: #00ab78;"></i>
            <span class="hide-menu ml-2">Dashboard</span>
          </a>
        </li> -->

        <li class="user-profile">
          <a
            (click)="toggleUserProfile()"
            class="has-arrow waves-effect waves-dark img_perfil"
            href="javascript:void(0)"
          >
          <i class="fa fa-exchange" style="color: #00ab78;"></i>
            <span class="hide-menu  ml-2">Cambio de usuario</span>
          </a>

          <!-- Toggle User Profile -->
          <ul *ngIf="isUserProfileExpanded" class="collapse show overflow_oculto">

            <li class="nav-devider"></li>
            <ng-container *ngIf="roles.length">
              <li class="user-profile" *ngFor="let rol of roles">
                <div
                  class="row hover_active sidebar-nav-item"
                  style="cursor: pointer"
                  (click)="changeRol(rol)"
                >
                  <div class="col-3">
                    <img class="avatar" [src]="rol.avatar | getImage" alt="user" />
                  </div>
                  <div class="col-9">
                    <h5 class="username">{{ rol.name }}</h5>
                    <small class="role">{{ getProfileRoleName(rol.rol) }}</small>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </li>

        <li class="nav-devider nav_devider_100"></li>

        <!-- Dynamic Menu Section -->
        <ng-container *ngFor="let m of menuItems">
          <ng-container *ngIf="m.active">
            <li class="nav-small-cap">
              <span class="hide-menu">{{ m.module }}</span>
            </li>

            <li *ngFor="let o of m.options">
              <a
                (click)="toggleSubMenu(o.option)"
                class="has-arrow waves-effect waves-dark"
                href="javascript:void(0)"
              >
                <i
                  style="color: #00ab78;"
                  [class]="o.icon"
                  [ngClass]="{
                    icon_active: o.option === opcion,
                    icon_green: o.option !== opcion
                  }"
                ></i>
                <span class="hide-menu span-sub">{{ o.option }}</span>
              </a>

              <!-- Toggle Submenu -->
              <ul *ngIf="expandedOption === o.option" class="collapse show">
                <li *ngFor="let sm of o.subMenu">
                  <a  *ngIf="sm.view"
                  [routerLink]="sm.route"
                  [ngClass]="{ 'active': selectedSubMenu === sm.name }"
                  (click)="selectSubMenu(sm.name)">
                  <i class="fa fa-minus mr-1"></i>
                  {{ sm.name }}
                  </a>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </nav>
  </div>
</aside>

<!-- Bottom Navbar for Mobile -->
<nav class="bottom-navbar " *ngIf="isMobile">
  <ul class="bottom-nav-list">
    <!-- Toggle User Profile Section -->
    <li class="user-profile">
      <a (click)="toggleUserProfile()" style="color: #fff; margin-top: 8px;" href="javascript:void(0)">
        <i class="fa fa-refresh icon-menu-user"></i>
        <span>Cambio de usuario</span>
      </a>
      <ul *ngIf="isUserProfileExpanded" class="bottom-submenu">
        <ng-container *ngIf="roles.length">
          <li *ngFor="let rol of roles">
            <a (click)="changeRol(rol)">
              <img class="avatar mr-3" [src]="rol.avatar | getImage" alt="user" /> <br>
              <span>{{ rol.name }}</span><br>
              <small>{{ getProfileRoleName(rol.rol) }}</small>
            </a>
          </li>
        </ng-container>
      </ul>
    </li>

    <!-- Dynamic Menu Section for Bottom Navbar -->
    <li *ngFor="let m of menuItems" >
      <i [class]="m.options[0].icon"></i>
      <span>{{ m.module }}</span>
      <ul class="bottom-submenu">
        <li *ngFor="let o of m.options">
          <a (click)="toggleSubMenu(o.option)">
          <span class="hide-menu span-sub" style="font-size: 14px;">
            <i
            style="color: #00ab78;"
            [class]="o.icon" ></i>{{ o.option }}</span>
            <ul *ngIf="expandedOption === o.option">
              <li *ngFor="let sm of o.subMenu">
                <custom-buttons
                  *ngIf="sm.view" style="margin-top: 10px;"
                  [routerLink]="sm.route"
                  [ngClass]="{ 'active': selectedSubMenu === sm.name }"

                  [text]="sm.name"
                  title="Permite loguearte"
                  textColor="#fff"
                  width="90%"
                  (click)="selectSubMenu(sm.name)"
                >
                </custom-buttons>
              </li>
            </ul>
            <hr>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
