<i class="fa fa-close close__button" (click)="close()" title="Cerrar configuración"></i>

<h3 class="title__information__shift">Información horario</h3>

<div class="contend__shift">
  <h4 class="doctor__name__schedule">{{ doctorSelected.name }}</h4>

  <div class="schedule__info">
    <h4 class="schedule__info__text"><strong>Estado: </strong> {{ statusShiftList[shiftSelected.shiftStatus].translate }}</h4>
    <h4 class="schedule__info__text"><strong>Duración por cita: </strong> {{ 60/shiftSelected.numberOfAppointments }} minutos</h4>
    <h4 class="schedule__info__text"><strong>Horario de atención: </strong>{{ hoursSchedule.startTime|formatHour }} - {{ hoursSchedule.endTime ? (hoursSchedule.endTime|formatHour) : 'no defida' }}</h4>
    <h4 class="schedule__info__text services"><strong>Servicios registrados:</strong></h4>
    <div class="schedule__info__services">
      <ul>
        <li *ngFor="let service of listCurrentServices">{{service.subserviceName}}</li>
      </ul>
    </div>
  </div>

  <form class="schedule__edit" [formGroup]="editTimeForm" autocomplete="off" *ngIf="editing">
    <form-select helper="Inicio horario de atención" label="Inicio" placeholder="Horario de inicio" [dataList]="startAvailableHours" valueField="hour" controlName="startHourSelector"></form-select>
    <form-select helper="Finalización horario de atención" label="Finalización" placeholder="Horario de finalización" [dataList]="endAvailableHours" valueField="hour" controlName="endHourSelector"></form-select>
  </form>

  <div class="schedule__info__services edit" *ngIf="editing">
    <span class="warning__message" [ngClass]="{show:showErrorOnservices}">
      <strong>* </strong>Se requiere al menos un elemento para continuar
    </span>
    <Strong>Editar servicios</Strong>
    <ul>
      <li *ngFor="let service of listServices">
        <input type="checkbox" class="input__form__checkbox" [id]="service._id" (change)="onChangeServices($event, service._id)" [checked]="isChecketService(service._id)">
        <label class="check__label" [for]="service._id">{{service.subserviceName}}</label>
      </li>
    </ul>
  </div>

  <div class="action__buttons">
    <button type="button" class="button__action action__remove" (click)="onRemoveSchedule()" *ngIf="!editing">
      Eliminar horario
    </button>
    <button type="button" class="button__action action__update" (click)="editing ? updateSchedule() : enableUpdate()" [disabled]="editing && !(hoursModified || servicesModified)">
      {{ editing ? "Guardar cambios" : "Editar horario" }}
    </button>
    <button type="button" class="button__action action__cancel" (click)="cancelUpdate()" *ngIf="editing">
      Cancelar
    </button>
  </div>
</div>

<div class="shift__loader" [ngClass]="{visible: loader}">
  <h2 class="title__animation">Horario eliminado</h2>
  <ng-lottie [options]="options"></ng-lottie>
</div>