import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-trinity-crud',
  templateUrl: './trinity-crud.component.html',
  styleUrls: ['./trinity-crud.component.scss']
})
export class TrinityCrudComponent implements OnInit {

  contactForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.maxLength(13) ,Validators.pattern(/^[0-9]{10}$/)]],
      city: ['', [Validators.required, Validators.minLength(3)]],
      acceptTerms: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.contactForm.valid) {
      const dataApi = this.contactForm.value;
      console.log('Datos para la API:', dataApi);
    }
  }
}
