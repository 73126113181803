import {  Component,  EventEmitter, Input, Output, TemplateRef } from '@angular/core';

export interface IStepper {
  contentTemplate: TemplateRef<any>;
  label: string;
  index: number;
}

@Component({
  selector: 'stepper',
  styleUrls: ['./stepper.component.scss'],
  templateUrl: './stepper.component.html'
})
export class StepperComponent {
  @Output() stepEvent: EventEmitter<any> = new EventEmitter()
  @Output() finishEvent: EventEmitter<any> = new EventEmitter()

  @Input({ required: true }) stepperList: IStepper[] = []
  @Input() textPrev: string = 'Anterior';
  @Input() textNext: string = 'Siguiente';
  @Input() textEnd: string = 'Finalizar';
  @Input() disablePrev: boolean = false;
  @Input() disableNext: boolean = false;
  @Input() canFinalize: boolean = false;
  @Input() bordered: boolean = true;
  @Input() initialVisibleSteps: number[] = []; // para controlar qué steps se muestran inicialmente
  @Input() buttonFixed: boolean = false;
  @Input() isLoggedIn: boolean = false;

  visibleSteps: number[] = []; // Array de steps visibles actualmente

  current: any = 1

  nextButtonText = this.textNext
  prevButtonText = this.textPrev

  ngOnInit() {
    if (this.initialVisibleSteps.length === 0) {
      this.visibleSteps = this.stepperList.map(step => step.index); // Si no se especifica initialVisibleSteps, se muestran todos los steps
    } else {
      this.visibleSteps = this.initialVisibleSteps;
    }
  }

  get currentStep(): IStepper | undefined {
    return this.stepperList.find(step => step.index === this.current);
  }

  setCurrentStep(index: number) {
    this.current = index;
  }

  prevStep() {
    let tmp = this.current - 1;
    if (tmp == 0) return;
    this.current = tmp; // Actualizar primero el índice actual.
    let data = {
      type: 'prev',
      index: this.current, // Ahora esto refleja el nuevo índice actual.
    };
    this.stepEvent.emit(data); // Emitir el evento después de actualizar el índice.
    this.nextButtonText = this.textNext;
  }
  
  nextStep() {
    let tmp = this.current + 1;
    if (tmp > this.stepperList.length) { // Cambiado de == a > para mayor claridad.
      this.finishEvent.emit();
      return;
    }
    this.current = tmp; // Actualizar primero el índice actual.
    let data = {
      type: 'next',
      index: this.current, // Ahora esto refleja el nuevo índice actual.
    };
    this.stepEvent.emit(data); // Emitir el evento después de actualizar el índice.
    if (tmp == this.stepperList.length) this.nextButtonText = this.textEnd;
  }

  // Nuevo método para filtrar los steps visibles
  getVisibleSteps(): IStepper[] {
    return this.stepperList.filter(step => this.visibleSteps.includes(step.index));
  }

  // Método para actualizar los steps visibles dinámicamente
  updateVisibleSteps(newVisibleSteps: number[]) {
    this.visibleSteps = newVisibleSteps;
  }
}