<div>
  <div class="row">
    <div class="col-12">
      <div class="config_container">
        <div class="container-title">
          <h3 class="animated fadeIn">Empresa&nbsp;</h3>
          <h3 class="title-desktop animated fadeIn">/ Horarios</h3>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-xl-6 col-md-6 col-xs-12">
          <div class="">
            <form class="" id="doctorSelector" [formGroup]="doctorForm" autocomplete="off">
              <form-select helper="Selección de doctor" label="Doctor o especialista" placeholder="Seleccione..."
                [dataList]="doctorList" idField="employee._id" valueField="name" controlName="doctorControl"
                (change)="changeDoctor()"></form-select>
            </form>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-xs-12">
          <app-branch-select-text (itemEmitter)="changeBranch($event)" id="branchSelector"></app-branch-select-text>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="calendar  extended">
  <div class="weekdays  extended">
    <span class="weekdays__item" *ngFor="let WeekName of weekDays" [title]="WeekName.name">{{ WeekName.name}}</span>
  </div>

  <div class="days__schedule extended" *ngIf="scheduleList" id="progcalendar">
    <div class="day" [ngClass]="scheduleList.sunday.dayStatus"
      (click)="addShift($event, scheduleList.sunday, 'sunday')">
      <div class="task__list" data-task-element="sunday" #sundayRef id="sundayRef"></div>
    </div>

    <div class="day" [ngClass]="scheduleList.monday.dayStatus" id="progcalendarday"
      (click)="addShift($event, scheduleList.monday, 'monday')">
      <div class="task__list" data-task-element="monday" #mondayRef id="mondayRef"></div>
    </div>

    <div class="day" [ngClass]="scheduleList.tuesday.dayStatus"
      (click)="addShift($event, scheduleList.tuesday, 'tuesday')">
      <div class="task__list" data-task-element="tuesday" #tuesdayRef id="tuesdayRef"></div>
    </div>

    <div class="day" [ngClass]="scheduleList.wednesday.dayStatus"
      (click)="addShift($event, scheduleList.wednesday, 'wednesday')">
      <div class="task__list" data-task-element="wednesday" #wednesdayRef id="wednesdayRef"></div>
    </div>

    <div class="day" [ngClass]="scheduleList.thursday.dayStatus"
      (click)="addShift($event, scheduleList.thursday, 'thursday')">
      <div class="task__list" data-task-element="thursday" #thursdayRef id="thursdayRef"></div>
    </div>

    <div class="day" [ngClass]="scheduleList.friday.dayStatus"
      (click)="addShift($event, scheduleList.friday, 'friday')">
      <div class="task__list" data-task-element="friday" #fridayRef id="fridayRef"></div>
    </div>

    <div class="day" [ngClass]="scheduleList.saturday.dayStatus"
      (click)="addShift($event, scheduleList.saturday, 'saturday')">
      <div class="task__list" data-task-element="saturday" #saturdayRef id="saturdayRef"></div>
    </div>

    <div class="calendar__loader" [ngClass]="{visible: loader}">
      <h2 class="title__animation">Cargando horarios</h2>
      <ng-lottie [options]="options"></ng-lottie>
    </div>
  </div>

  <div class="range__hours">
    <span class="range__hours__item" *ngFor="let hour of hourRange">{{hour}}</span>
  </div>
</div>

<div class="container-time-range">
  <ng-container *ngIf="!isInputEnabled; else timeEdit">
    <div class="input-view-select">
      <span>
        {{ formatToAmPm(minHour) }} - {{ formatToAmPm(maxHour) }}
      </span>
    </div>

    <div class="button-view">
      <custom-buttons class="button-data" [title]="'Edita el rango de su horario'" inputBackgroundColor="primary"
        [text]="'Editar horas '" width="144px" (click)="enableEdit()" icon="fa fa-edit">
      </custom-buttons>
    </div>
  </ng-container>

  <ng-template #timeEdit>
    <input type="time" step="3600" [(ngModel)]="minHour" [disabled]="!isInputEnabled" placeholder="Horas mínimas"
      (change)="onTimeChange('min')" class="time-input" />
    <input type="time" step="3600" [(ngModel)]="maxHour" [disabled]="!isInputEnabled" placeholder="Horas máximas"
      (change)="onTimeChange('max')" class="time-input" />
    <custom-buttons class="button-data" [title]="'Edita el rango de su horario'" inputBackgroundColor="secondary"
      [text]="'Guardar horas'" width="160px" (click)="onSave()" icon="fa fa-save">
    </custom-buttons>
  </ng-template>

  <!-- Contenedor alineado a la derecha -->
  <div class="right-align">
    <ng-container *ngIf="!multiSelectMode; else textSelect">
      <div class="input-view-select-right input-view">
        <span>
          Multiselección
        </span>
      </div>
      <div class="button-position">
        <custom-buttons class="button-data" [title]="'Edita el rango de su horario'" inputBackgroundColor="primary"
          [text]="'Activar '" width="144px" (click)="toggleMultiSelect()" icon="fa fa-clone">
        </custom-buttons>
      </div>
    </ng-container>
    <ng-template #textSelect>
      <div class="input-view-select-right input-view">
        <span>
          {{ getTotalHoursCount() }}: turnos seleccionados
        </span>
      </div>
      <div class="button-position">
        <custom-buttons class="button-data" [title]="'Edita el rango de su horario'" inputBackgroundColor="gray"
          [text]="'Cancelar '" width="144px" (click)="toggleMultiSelect()" icon="fa fa-minus-square-o">
        </custom-buttons>
        <custom-buttons class="button-data" [title]="'Edita el rango de su horario'" inputBackgroundColor="danger"
          [text]="'Eliminar '" width="144px" (click)="onRemoveSchedule()" icon="fa fa-trash-o">
        </custom-buttons>
      </div>
    </ng-template>

  </div>


  <!-- <div class="right-align">
    <div class="input-view-select input-view">
      <span>
        {{ !multiSelectMode ? 'Multiselección desactivada' : selectedShifts.size + ' seleccionados' }}
      </span>
    </div>
    <div class="button-position">
      <button class="button-dynamic" (click)="toggleMultiSelect()">
        Multiselección: {{ multiSelectMode ? 'Desactivada' : 'Activada' }}
      </button>

     <custom-buttons class="button-data" [title]="'Edita el rango de su horario'" inputBackgroundColor="primary"
      [text]="'Editar horas '" width="144px" (click)="enableEdit()" icon="fa fa-edit">
      </custom-buttons> 
    </div>

  </div> -->


</div>


<div class="calendar__schedule__legend">
  <span class="calendar__schedule__legend__item" title="Representa un dia de la semana activo">
    <span class="calendar__schedule__legend__item__icon active"></span>
    Día activo
  </span>

  <span class="calendar__schedule__legend__item canceled" title="Representa un dia de la semana inactivo">
    <span class="calendar__schedule__legend__item__icon inactive"></span>
    Día inactivo
  </span>

  <span class="calendar__schedule__legend__item canceled" title="Representa un dia de la semana pausado">
    <span class="calendar__schedule__legend__item__icon paused"></span>
    Día pausado
  </span>

  <span class="calendar__schedule__legend__item" title="Representa un dia de la semana bloquedo">
    <span class="calendar__schedule__legend__item__icon blocked"></span>
    Día bloqueado
  </span>
</div>