import { Component, EventEmitter, Output, OnInit, Inject, OnDestroy  } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InformationService } from 'src/app/services/information.service';
import { Router } from '@angular/router';
import { SweetAlertService } from '@services';
import { SearchRequest } from '@interfaces/shared-components.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';
//import { DOCUMENT } from '@angular/common';


interface AccordionItem {
  title: string;
  description: string;
  isOpen: boolean;
}

@Component({
  selector: 'app-landing-content',
  templateUrl: './landing-content.component.html',
  styleUrls: ['./landing-content.component.scss'],
})
export class LandingContentComponent  implements OnInit, OnDestroy{
  @Output() dataForm: EventEmitter<any[]> = new EventEmitter();

  options: AnimationOptions = {
    path: 'assets/lottie-animations/doctor.json',
  };

  //Specialities list
  public searchServiceForm!: FormGroup;
  public municipalities: any[] = [];

  //Doctors
  doctors = [
    {
      img: 'https://i.pravatar.cc/150?img=1',
      name: 'Jane Doe',
      specialty: 'Optometría',
    },
    {
      img: 'https://i.pravatar.cc/150?img=2',
      name: 'John Doe',
      specialty: 'Optometría',
    },
    {
      img: 'https://i.pravatar.cc/150?img=1',
      name: 'Jane Doe',
      specialty: 'Optometría',
    },
    {
      img: 'https://i.pravatar.cc/150?img=2',
      name: 'John Doe',
      specialty: 'Optometría',
    },
    {
      img: 'https://i.pravatar.cc/150?img=7',
      name: 'Dr. Alice Smith',
      specialty: 'Cardiología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=8',
      name: 'Dr. Bob Johnson',
      specialty: 'Neurología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=9',
      name: 'Dr. Carol Williams',
      specialty: 'Dermatología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=10',
      name: 'Dr. David Brown',
      specialty: 'Pediatría',
    },
    {
      img: 'https://i.pravatar.cc/150?img=11',
      name: 'Dr. Eva Davis',
      specialty: 'Ginecología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=12',
      name: 'Dr. Frank Wilson',
      specialty: 'Oftalmología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=13',
      name: 'Dr. Grace Miller',
      specialty: 'Ortopedia',
    },
    {
      img: 'https://i.pravatar.cc/150?img=14',
      name: 'Dr. Henry Moore',
      specialty: 'Psiquiatría',
    },
    {
      img: 'https://i.pravatar.cc/150?img=15',
      name: 'Dr. Irene Taylor',
      specialty: 'Endocrinología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=16',
      name: 'Dr. Jake Anderson',
      specialty: 'Urología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=17',
      name: 'Dr. Kathy Hernandez',
      specialty: 'Reumatología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=18',
      name: 'Dr. Luis Martinez',
      specialty: 'Oncología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=19',
      name: 'Dr. Monica Gonzalez',
      specialty: 'Nefrología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=20',
      name: 'Dr. Nick Clark',
      specialty: 'Anestesiología',
    },
    {
      img: 'https://i.pravatar.cc/150?img=21',
      name: 'Dr. Olivia Lewis',
      specialty: 'Gastroenterología',
    },
  ];
  // Array de Links Header
  links = [
    { name: 'Inicio' ,
      targetId: 'home'
    },
    { name: 'Descargas',
      targetId:'app'
    },
    { name: 'FAQ',
    targetId:'faq'
  }
  ]
  // Array de FAQ
  items: AccordionItem[] = [
    {
      title: '¿Cuánto cuesta la inscripción?',
      description: 'La inscripción es GRATIS para el titular y hasta 10 miembros de su familia, además puedes registrar a tus mascotas. Solo pagas la cita médica menos el descuento cuando la necesites',
      isOpen: false
    },
    {
      title: '¿Cómo puedo agendar una cita médica?',
      description: 'Descarga la app e inscríbete con tu grupo familiar. Busca el servicio, el descuento y el médico de tu preferencia, haz clic en "Solicitar cita" y ¡listo!',
      isOpen: false
    },
    {
      title: '¿Cuáles son los beneficios para los afiliados?',
      description: 'Obtén descuentos en tus citas particulares con los mejores médicos de la ciudad para ti y hasta 10 miembros de tu familia. No hay pago de suscripción anual ni mensual por estar inscrito. Solo pagas la cita médica menos el descuento cuando la necesites',
      isOpen: false
    },
    {
      title: '¿Puedo agendar citas para mis mascotas?',
      description: '¡Sí! Porque amamos a las mascotas, ofrecemos los mejores servicios en veterinaria y belleza canina a precios que siempre has querido',
      isOpen: false
    },
    {
      title: '¿Tengo que pagar mensualidad por estar inscrito en la app?',
      description: '¡No! DESCUENTOS MÉDICOS App es GRATIS. Próximamente, tendremos más beneficios en una versión VIP que podrás elegir',
      isOpen: false
    },
  ];
  // Array contenido cards
  cards = [
    {
      img: 'https://kit.glmedialibrary.com/8707/305.png?tr=w-400',
      title: 'Citas de manera rápida y eficiente',
      description: 'Solicita tus citas médicas particulares y agéndalas en máximo 2 minutos desde la aplicación, disponible 24/7'
    },
    {
      img: 'https://kit.glmedialibrary.com/8707/IMAGENESWEB03.png_2?tr=w-400',
      title: 'Tarifas Preferenciales',
      description: 'Escoge el precio que más te convenga. Obtén descuentos del 10%, 20%, 30% y hasta el 50% en tus servicios de salud particulares '
    },
    {
      img: 'https://kit.glmedialibrary.com/8707/IMAGENESWEB02.png?tr=w-400',
      title: 'Los mejores médicos están aquí',
      description: 'Busca en la App el médico que necesitas usando palabras clave, el nombre del servicio, o el nombre del médico o especialista. Solicita la cita con un clic y obtén tu descuento'
    }
  ]
  // Array contenido Testimonios
  testimonials = [
    {
      img: 'https://kit.glmedialibrary.com/8707/hombre2.png?tr=w-100?tr=w-300',
      name: 'Andrés Llorente',
      testimony: '“Es una excelente aplicación para toda la familia y en especial para nuestros hijos peluditos. Obtuvimos descuentos en nuestras consultas y pudimos gestionar las citas de manera fácil y rápida”',
      occupation: 'Contador '
    },
    {
      img: 'https://kit.glmedialibrary.com/8707/mujer2.png?tr=w-100?tr=w-300',
      name: 'Ana Santander',
      testimony: '“Estoy muy satisfecho con el servicio de DESCUENTOS MÉDICOS App. Gracias a ellos, pude encontrar un especialista que me brindó la atención que necesitaba. Lo recomiendo”',
      occupation: 'Ingeniera'
    },
    {
      img: 'https://kit.glmedialibrary.com/8707/mujer1.png?tr=w-100?tr=w-300',
      name: 'Sara Solarte',
      testimony: '“¡Esta aplicación de DESCUENTOS MÉDICOS es realmente útil! Me ha ahorrado dinero en mis visitas al médico”',
      occupation: 'Docente'
    }
  ]


 // buttonState: boolean = true;
  searchResults: any;
  term: string = '';
  municipalityId: string = '';
  isLoaded : boolean = false;
  videoUrl!: SafeResourceUrl;
  videoVisible: boolean = false;
  buttonVisible: boolean = true;
  private static pixelCargado = false; // Variable de control estática

  // Contador de usuarios
  defaultValue: number = 138600;
  usersValueAfter!: number;
  usersValue!: number;
  intervalId: any;




  constructor(
    private infoService: InformationService,
    private router: Router,
    private swalService: SweetAlertService,
    private sanitizer: DomSanitizer,
    private smoothScrollService: SmoothScrollService,
   // @Inject(DOCUMENT) private document: Document
  ) {

    this.inicializarCompanyForm();

    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/4K98CzmgQYI');
    this.searchServiceForm = new FormGroup({
      municipality: new FormControl(''),
      searchData: new FormControl(''),
    });
  }

  ngOnInit(): void {

    //Para aumentar los usuarios
    //this.startIncrementing(138600);
    //Para aumentar los especialistas

    //Para aumentar los servicios

    this.iniciarData();
    //this.subscribeToFormChanges();
   // this.inicializarCompanyForm();
    this.videoVisible = false;
    this.buttonVisible = true;
    /*
    if(!LandingContentComponent.pixelCargado){
      this.agregarPixelMeta();
      LandingContentComponent.pixelCargado = true;
    }
    */
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  iniciarData() {
    this.infoService
      .getMunicipalities()
      .then((resp: any) => {
        this.municipalities = resp;
        ////console.log(this.municipalities);
      })
      .catch(() => {
        this.municipalities = [];
      });
  }

  inicializarCompanyForm() {
    this.searchServiceForm = new FormGroup({
      municipality: new FormControl('', Validators.required),
      searchData: new FormControl('', Validators.required),
    });
  }

  searchSubservices() {
    // Verifica si hay información suficiente para realizar la búsqueda
    let municipalityControl = this.searchServiceForm.get('municipality');
    let municipalityValue = municipalityControl
      ? municipalityControl.value
      : null;

    let searchDataControl = this.searchServiceForm.get('searchData');
    let searchDataValue = searchDataControl ? searchDataControl.value : null;

    if (municipalityValue && searchDataValue) {
      // console.log('Realizando búsqueda...', municipalityValue._id,searchDataValue);
      //console.log('valor del inpút de munipio', municipalityValue);


      // Interface del objeto que se va a buscar
      const searchRequest: SearchRequest = {
        municipalityId: municipalityValue._id,
        term: searchDataValue,
        filters: {
          price: {
            enabled: false,
            min: 0,
            max: 0,
          },
          provider: true,
          doctor: true,
          services: {
            pets: true,
            humans: true,
          },
        },
      };

      // Llama al servicio InformationService.search para realizar la búsqueda
      this.infoService.search(searchRequest).subscribe(
        (searchResults: any) => {
          //console.log('Resultados de la búsqueda:', searchResults);

          // validaciones
          if (searchResults.body || searchResults.ok == true) {
            let mun = municipalityValue._id;
            let search = searchDataValue;
            let city =  municipalityValue.municipality;
            this.isLoaded = true;
            // console.log('ciudad del select', JSON.stringify(city));
            

            // redirigir con los datos  obtenidos de la busqueda.
            this.router.navigate(['/directory'], {
              queryParams: {
                mun,
                city: JSON.stringify(city),
                search
              },
            });
          }
          else{
            this.isLoaded = false;

          }
        },
        (searchError: any) => {
          console.error('Error en la búsqueda:', searchError);
        }
      );
    } else {
      // Si falta información, muestra un mensaje de error
      this.swalService.lauchSwal(
        '',
        'Ingresa tu ubicación y busqueda <br> (especialidad, médico o afección)',
        'warning'
      );
      //console.error('Falta información para realizar la búsqueda.');
    }
  }

  volverArriba() {
    let posicion = window.pageYOffset;
    const intervalId = setInterval(() => {
      window.scrollTo({ top: posicion, behavior: 'auto' });
      if (posicion > 0) {
        posicion -= 30; // ajusta este número para cambiar la velocidad de la animación
      } else {
        clearInterval(intervalId);
      }
    }, 10); // este número está relacionado con la frecuencia de actualización de los navegadores (60Hz = 16.67ms por fotograma)
  }

  // Aparecer el video y desaparecer el objeto que invoca el metodo
  viewVideo(){
    this.videoVisible = !this.videoVisible;
    this.buttonVisible = false;
  }

  // Redirigir a solicitar cita
  redirectAppointment() {
    this.router.navigate(['/directory']);

  }

  // Redirigir a profesional de la salud
  redirectProfessional(){
    this.router.navigate(['/info/doctor']);
  }

  // Hace scroll a la section Download
  scrollDonwload(){
    this.smoothScrollService.scrollToElement('app');
  }


  toggleItem(index: number): void {
    this.items[index].isOpen = !this.items[index].isOpen;

    this.items.forEach((item, i) => {
      if (i !== index) item.isOpen = false;
    });
  }


  abrirWhatsApp() {
    const url = 'https://api.whatsapp.com/send?phone=573174785093&text=Hola!%20me%20interesa%20saber%20de%20la%20aplicación';
    window.open(url, '_blank');
  }
}
