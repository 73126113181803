
<!-- ============================================================== -->
<!-- Page wrapper  -->
<!-- ============================================================== -->
<div class="page-wrapper" >
  <!-- ============================================================== -->
  <!-- Container fluid  -->
  <!-- ============================================================== -->
  <div class="container-fluid-temp" >
    <!-- ============================================================== -->
    <!-- Start Page Content -->
    <!-- ============================================================== -->
    <div class="fix-width" >
      <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-12 container-agreement">
          <div class="text-center animated bounceInUp">
            <img class="img-logo text-center" [src]="selectedData?.principal?.img[1]?.path" />
          </div>
          <h1 *ngIf="selectedData?.principal?.title[0]?.active"
              class="text-center title-mobile animated bounceInUp"
              [ngStyle]="{
                'color': stylesAgreement?.pPrimary,
                'font-family': stylesAgreement?.pSemiBold
              }">
            {{ selectedData?.principal?.title[0]?.text }}
          </h1>
          <h2 *ngIf="selectedData?.principal?.subTitle[0]?.active"
              class="text-center subtitle-mobile animated bounceInUp"
              [ngStyle]="{
                'color': stylesAgreement?.pPrimary,
                'font-family': stylesAgreement?.pSemiBold
              }">
            {{ selectedData?.principal?.subTitle[0]?.text }}
          </h2>
          <p *ngIf="selectedData?.principal?.description[0]?.active"
             class="text-center description-mobile animated bounceInUp"
             [ngStyle]="{
               'color': stylesAgreement?.pGray,
               'font-family': stylesAgreement?.oRegular
             }">
            {{ selectedData?.principal?.description[0]?.text }}
          </p>
        </div>

        <div class="col-lg-5 col-md-5 col-sm-12 lottie-container">
          <div class="text-center lottie-class animated bounceInUp">
            <div class="text-center" style="height: 58vh;">
              <ng-lottie [options]="options"></ng-lottie>
            </div>
          </div>
        </div>
      </div>

      <!-- form -->

      <!-- form -->
      <div class="down-btn animated bounceInUp form_container">
        <form [formGroup]="searchServiceForm" (ngSubmit)="searchSubservices()">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 btn_align">
              <form-autocomplete label="¿En que ciudad quieres tu cita?" labelColor="#fff" [required]="false"
                [placeholder]="'Busca tu departamento o municipio...'" [dataList]="municipalities"
                [controlName]="'municipality'" [idField]="'_id'" [valueField]="'municipality'">
              </form-autocomplete>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 btn_align">
              <form-input helper="" label="Especialidad, médico o afección:" [required]="false" labelColor="#fff"
                placeholder="Buscar..." [controlName]="'searchData'" [type]="'text'"></form-input>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 text-center btn_align mt-4">
              <button class="nav-link btn-search btn-block btn_size font-medium text-white"
              [ngStyle]="
              {
                'background': stylesAgreement?.pPrimary,
              }"
              type="submit">
                Busca tu cita <i class="ti-search ml-2"></i>
              </button>
            </div>
          </div>
        </form>
      </div>

      <section id="faq" class="frecuentes">
        <div class="section-descr text-center">
          <h1 class="text-center title-mobile animated bounceInUp" [ngStyle]="{
            'color': stylesAgreement?.pPrimary,
            'font-family': stylesAgreement?.pSemiBold,
            'margin-top': '50px',
            'margin-bottom': '50px'
          }">
            Preguntas frecuentes
          </h1>
          <div class="accordion">
            <div class="accordion-content" *ngFor="let item of items; let i = index">
              <div (click)="toggleItem(i)" class="prevenir-preguntas">
                <header>
                  <span class="title">{{ item?.title }}</span>
                  <i
                  [ngStyle]="
                  {
                    'color': stylesAgreement?.pWhite,
                  }"
                  class="fa"

                  [class]="item?.isOpen ? 'fa-solid fa-minus' : 'fa-solid fa-plus'"></i>
                </header>
                <p class="description" [class.open]="item?.isOpen">
                  {{ item?.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- form -->

      <!-- form -->

      <!-- ============================================================== -->
      <!-- Doctors Section -->
      <!-- ============================================================== -->
      <div *ngIf="selectedData?.secondary[0]" style="margin-top: 40px;">
        <h1 class="text-center subtitle-mobile" [ngStyle]="{
          'color': stylesAgreement?.pPrimary,
          'font-family': stylesAgreement?.pSemiBold
        }">
          {{ selectedData?.secondary[0]?.sectionTitle[0]?.text }}
        </h1>
        <div class="container_doctors text-center">
          <div *ngFor="let doctor of doctors">
            <img [src]="doctor?.img" alt=""/>
          </div>
        </div>
      </div>
    </div>

    <!-- ============================================================== -->
    <!-- Featured Section -->
    <!-- ============================================================== -->
    <div *ngIf="selectedData?.secondary[1]" class="row page-wrapper2">
      <div class="col-md-12" id="demos">
        <div class="max-width">
          <section class="section overfree">
            <div class="">
              <div class="section-descr text-center">
                <h1 class="font-bold text-white">
                  {{ selectedData?.secondary[1]?.sectionTitle[0]?.text }}
                </h1>
                <p class="text-center text-white description-mobile animated bounceInUp" [ngStyle]="{
                  'font-family': stylesAgreement?.oRegular
                }">
                  {{ selectedData?.secondary[1]?.sectionDescription[0]?.text }}
                </p>
              </div>
            </div>
            <div class="text-center">
                <div class="row">
                  <div class="col-lg-6 text-center">
                    <iframe class="video-youtube" loading="lazy" [src]="videoUrl1" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                  </iframe>
                  </div>
                  <div class="col-lg-6 text-center">
                    <iframe class="video-youtube" loading="lazy" [src]="videoUrl2" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                  </iframe>
                  </div>
              </div>
            </div>
            <!-- end container -->
          </section>
          <section class="section overfree">
            <div class="space_median">
              <div class="section-descr text-center">
                <h1 class="font-bold text-white">
                  {{ selectedData?.secondary[2].sectionTitle[0].text }}
                </h1>
                <hr />
                <p class="text-center text-white description-mobile animated bounceInUp" [ngStyle]="{
                'font-family': stylesAgreement?.oRegular,
                }">
                  {{ selectedData?.secondary[2].sectionDescription[0].text }}
                </p>
              </div>

              <div class="row custom-features">
                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-line-chart"></i> Escalamiento
                    </h3>
                    <span class="font-medium text-white">
                      Nuestra plataforma crece continuamente, ampliando alianzas para que más usuarios en el país accedan a atención médica de calidad.
                    </span>
                  </div>
                  <!-- end feature-seo -->
                </div>
                <!-- end col -->

                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-search"></i> Selección de profesionales
                    </h3>
                    <span class="font-medium text-white">
                      En Descuentos Médicos, solo incluimos profesionales de salud habilitados por el Ministerio de Salud ReTHUS, asegurando confianza en la atención médica.
                    </span>
                  </div>
                  <!-- end feature-seo -->
                </div>
                <!-- end col -->

                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-mobile"></i> Fácil de Usar
                    </h3>
                    <span class="font-medium text-white">
                      Simplificamos el agendamiento de citas particulares, sin llamadas ni complicaciones; todo en tiempo real desde tu computadora o celular.
                    </span>
                  </div>
                  <!-- end feature-seo -->
                </div>
                <!-- end col -->

                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-folder-open-o"></i> Actualizaciones
                      Constantes
                    </h3>
                    <span class="font-medium text-white">
                      Nuestra plataforma se mantiene actualizada con información de médicos, servicios y descuentos para asegurar una experiencia óptima.
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-star"></i> Descuentos Exclusivos
                    </h3>
                    <span class="font-medium text-white">
                      Accede a tarifas preferenciales en atención médica particular gracias a nuestros convenios con los mejores médicos y especialistas.
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="feature-seo">
                    <h3 class="text-white font-bold">
                      <i class="fa fa-code"></i> Soporte y Asesoramiento
                    </h3>
                    <span class="font-medium text-white">
                      Nuestro equipo de atención al cliente está disponible en horario de oficina para ayudarte con agendamientos, dudas y mejorar tu experiencia en Descuentos Médicos, garantizando una atención excepcional.
                    </span>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end container -->
          </section>
          <!-- end section -->

        </div>
      </div>
    </div>


    <!-- ============================================================== -->
    <!-- Download app Section -->
    <!-- ============================================================== -->

    <section class="testimonios">
      <div class="container-slider">
        <div id="carouselExampleIndicators" class="carousel slide " data-ride="carousel">
          <div class="carousel-inner" role="listbox">
            <div *ngFor="
                      let testimony of testimonials;
                      let i = index
                    " class="carousel-item  carousel_item_style" [ngClass]="{ active: i === 0 }">
              <!-- CARD -->
              <article class="card-testimonio">
                <div class="container-top">
                  <div class="container-img">
                    <img [src]="testimony.img " [alt]="'Slide ' + (i + 1)" >
                  </div>
                  <div class="container-text-testimonio">
                    <p class="name-testimonio">{{testimony.name}}</p>
                    <p class="occupation-testimonio">{{testimony.occupation}}</p>
                  </div>
                  <div class="container-sticker">
                    <img class="comillas" src="../../../../assets/LANDING GENERATOR/icons8-comillas-96.png" alt="">
                  </div>
                </div>

                <div class="container-content-testimonio">
                  <p class="testimony-testimonio">{{testimony.testimony}}</p>
                </div>
              </article>

            </div>
          </div>
          <!-- Button prev -->
          <a class="carousel-control-prev button-prev" href="#carouselExampleIndicators" role="button"
            data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <!-- Button next -->
          <a class="carousel-control-next button-next" href="#carouselExampleIndicators" role="button"
            data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </section>


    <!-- ============================================================== -->
    <!-- Download app Section -->
    <!-- ============================================================== -->
    <div class="fix-width">
        <div class="col-lg-12 py-5 text-center">
          <img class="img-logo" src="./assets/images/logo_black_font.png" alt="logo" />
          <h2 class="text-center description-mobile animated bounceInUp" [ngStyle]="{
            'color': stylesAgreement?.pGray,
            'font-family': stylesAgreement?.oRegular,
            }">
           ¡Descarga la App y empieza a ahorrar ahora!
          </h2>
          <a href="https://apps.apple.com/co/app/prevenir-descuentos-m%C3%A9dicos/id1560589182"
            title="Descargar desde Apple Store" class="btn btn-store aos-init aos-animate">
            <img src="./assets/images/stores/ios.png" style="height: 60px; width: 200px" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.prevenirdescuentosmedicos.app"
            title="Descargar desde Play Store" class="btn btn-store aos-init aos-animate">
            <img src="./assets/images/stores/android.png" style="height: 60px; width: 200px" />
          </a>
          <a href="https://appgallery.cloud.huawei.com/ag/n/app/C104125813?locale=es_US&source=appshare&subsource=C104125813"
            title="Descargar desde Play Store" class="btn btn-store aos-init aos-animate">
            <img src="./assets/images/stores/gallery.png" style="height: 60px; width: 200px" />
          </a>
        </div>
    </div>



    <!-- ============================================================== -->
    <!-- Back up button -->
    <!-- ============================================================== -->

    <button class="bt-wpp btn btn-circle btn-lg" type="button" (click)="abrirWhatsApp()">
      <i class="text-white font-bold fa fa-whatsapp"></i>
    </button>

    <button class="bt-top btn btn-circle btn-lg "
      [ngStyle]="
      {
        'background': stylesAgreement?.pPrimary,
      }"
      type="button" (click)="volverArriba()">
      <i class="text-white font-bold fa fa-chevron-up"></i>
    </button>


  </div>
  <!-- ============================================================== -->
  <!-- End Container fluid  -->
  <!-- ============================================================== -->
</div>
<app-landing-footer [isDescMed] = "false"></app-landing-footer>
